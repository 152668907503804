import Loader from '@/authenticated/components/layout/Loader'
import { useAppDispatch, useAppSelector, useUrlSearchParam } from '@/hooks'
import { Redirect } from 'react-router-dom'
import AutogridConfirmation from '../confirmation'
import { getCurrentUser } from '@/actions/user'
import { useEffect, useState } from 'react'

export default function PartnerLogin() {
  const isExistingUser = useUrlSearchParam('existingUser') === 'true'
  const programIdParam = useUrlSearchParam('program_id')
  const [loadingUser, setLoadingUser] = useState(true)
  const dispatch = useAppDispatch()
  const authSuccess = useAppSelector(
    (state) => state.auth.access?.token && !state.auth.authLoading,
  )
  const authFailed = useAppSelector(
    (state) => Object.keys(state.auth.errors ?? {}).length > 0,
  )

  const updateUser = async () => {
    if (authSuccess) {
      await dispatch(getCurrentUser())
    }
    setLoadingUser(false)
  }

  useEffect(() => {
    // Front-load getting the user so we can show user related data
    // in the confirmation page
    updateUser()
  }, [authSuccess])

  if (isExistingUser) {
    return <AutogridConfirmation existingUser />
  }

  return authSuccess && !loadingUser ? (
    <Redirect to={`/autogrid/confirmation?program_id=${programIdParam}`} />
  ) : (
    <div className="flex justify-center w-full h-full mt-10">
      {authFailed ? (
        <div>
          <h1>Authentication failed</h1>
          <p>There was an error authenticating your account</p>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  )
}
